import React from "react"
import "./Education.scss"


export default ({ children }) => {
  // return <div className="box-n">{children}</div>
  return (

    <section class="main--container">

      <a target="_blank"
         href={"https://www.lrt.lt/naujienos/gyvenimas/13/2106460/nacionalinis-aplinkosaugos-egzaminas-jau-si-antradieni"}>
        <article className="card">
          <img src="https://www.lrt.lt/img/2023/10/23/1612577-700537-393x221.jpg" alt=""/>
          <article className="content">
            <h6>Nacionalinis aplinkosaugos egzaminas – jau šį antradienį</h6>
            <p>
            </p>
          </article>
        </article>
      </a>

      <a target="_blank"
         href={"https://www.lrt.lt/naujienos/gyvenimas/13/2103873/16-metu-mesos-nevalganti-meda-lietuvoje-truksta-suvokimo-kokia-itaka-klimatui-daro-musu-mityba"}>
        <article className="card">
          <img src="https://www.lrt.lt/img/2023/08/17/1569588-190400-393x221.jpg" alt=""/>
          <article className="content">
            <h6>16 metų mėsos nevalganti Meda: Lietuvoje trūksta suvokimo, kokią įtaką klimatui daro mūsų mityba</h6>
            <p>
            </p>
          </article>
        </article>
      </a>


      <a target="_blank"
         href={"https://www.lrt.lt/naujienos/lietuvoje/2/2101480/kai-siuksliavezis-nuvaziuoja-kaip-toliau-rusiuojamos-atliekos-kai-jus-savo-darba-jau-padarete"}>
        <article className="card">
          <img src="https://www.lrt.lt/img/2023/09/28/1596965-562108-393x221.jpg" alt=""/>
          <article className="content">
            <h6>Kai šiukšliavežis nuvažiuoja: kaip toliau rūšiuojamos atliekos, kai jūs savo darbą jau padarėte</h6>
            <p>
            </p>
          </article>
        </article>
      </a>

      <a target="_blank"
         href={"https://www.lrt.lt/naujienos/gyvenimas/13/2084489/ar-gedimino-bokstas-atsidurs-po-vandeniu-kviecia-ruostis-nacionaliniam-aplinkosaugos-egzaminui"}>
        <article className="card">
          <img src="https://www.lrt.lt/img/2022/09/05/1336277-338000-393x221.jpg" alt=""/>
          <article className="content">
            <h6>Ar Gedimino bokštas atsidurs po vandeniu? Kviečia ruoštis Nacionaliniam aplinkosaugos egzaminui</h6>
            <p>
            </p>
          </article>
        </article>
      </a>

      <a target="_blank"
         href={"https://www.lrt.lt/naujienos/lietuvoje/2/1848879/zaliosios-politikos-instituto-analitike-uztvankos-prasto-ekologinio-rastingumo-zenklai"}>
        <article className="card">
          <img src="https://www.lrt.lt/img/2022/12/16/1408954-662715-393x221.jpg" alt=""/>
          <article className="content">
            <h6>Žaliosios politikos instituto analitikė: užtvankos – prasto ekologinio raštingumo ženklai</h6>
            <p>
            </p>
          </article>
        </article>
      </a>

      <a target="_blank"
         href={"https://www.lrt.lt/naujienos/gyvenimas/13/1826969/nacionalini-aplinkosaugos-egzamina-visuomene-islaike-penketui"}>
        <article className="card">
          <img src="https://www.lrt.lt/img/2019/07/31/482999-997702-393x221.jpg" alt=""/>
          <article className="content">
            <h6>Nacionalinį aplinkosaugos egzaminą visuomenė išlaikė penketui</h6>
            <p>
            </p>
          </article>
        </article>
      </a>

      <a target="_blank"
         href={"https://www.lrt.lt/naujienos/gyvenimas/13/1798058/vilnieciai-gali-didziuotis-vienu-seniausiu-misku-miesto-centre-ka-slepia-ilgaamzis-vingio-parkas"}>
        <article className="card">
          <img src="https://www.lrt.lt/img/2022/10/10/1360198-888233-393x221.jpg" alt=""/>
          <article className="content">
            <h6>Vilniečiai gali didžiuotis vienu seniausių miškų miesto centre – ką slepia ilgaamžis Vingio parkas?</h6>
            <p>
            </p>
          </article>
        </article>
      </a>

      <a target="_blank"
         href={"https://www.lrt.lt/naujienos/gyvenimas/13/1779750/oro-tarsa-kaip-sostine-kovoja-su-nematomu-priesu"}>
        <article class="card">
          <img src="https://www.lrt.lt/img/2020/11/19/775838-281213-393x221.jpg" alt=""/>
          <article class="content">
            <h6>Oro tarša – kaip sostinė kovoja su nematomu priešu?</h6>
            <p>
            </p>
          </article>
        </article>
      </a>


      <a target="_blank"
         href={"https://www.lrt.lt/naujienos/gyvenimas/13/1772761/kviecia-registruotis-i-pirmaji-lietuvoje-nacionalini-aplinkosaugos-egzamina"}>
        <article className="card">
          <img src="https://www.lrt.lt/img/2022/09/05/1336277-338000-393x221.jpg" alt=""/>
          <article className="content">
            <h6>Kviečia registruotis į pirmąjį Lietuvoje Nacionalinį aplinkosaugos egzaminą</h6>
            <p>
            </p>
          </article>
        </article>
      </a>

      <a target="_blank"
         href={"http://zaliojipolitika.lt/kada-pasieksime-planetos-luzio-taska/"}>
        <article className="card">
          <img src="https://aplinkosaugosegzaminas.lrt.lt/static-nae/earth-g674363e7b_1920-1080x675.jpg" alt=""/>
          <article className="content">
            <h6>Kada pasieksime planetos „lūžio tašką“?</h6>
            <p>
            </p>
          </article>
        </article>
      </a>

      <a target="_blank"
         href={"http://zaliojipolitika.lt/vienkartiniu-plastiko-gaminiu-draudimas-kaip-reaguoti-pastebejus-pazeidima/"}>
        <article className="card">
          <img src="https://aplinkosaugosegzaminas.lrt.lt/static-nae/drinking-straws-1039328_1920-1080x675.jpg" alt=""/>
          <article className="content">
            <h6>Vienkartinių plastiko gaminių draudimas: kaip reaguoti pastebėjus pažeidimą?
            </h6>
            <p>
            </p>
          </article>
        </article>
      </a>

      <a target="_blank"
         href={"http://zaliojipolitika.lt/nyderlandu-kova-su-potvyniais-ar-lietuva-gali-istikti-toks-pat-likimas/"}>
        <article className="card">
          <img src="https://aplinkosaugosegzaminas.lrt.lt/static-nae/flood-gce9f38a27_1920-1080x675.jpg" alt=""/>
          <article className="content">
            <h6>Nyderlandų kova su potvyniais: ar Lietuvą gali ištikti toks pat likimas?
            </h6>
            <p>
            </p>
          </article>
        </article>
      </a>

      <a target="_blank"
         href={"http://zaliojipolitika.lt/kodel-mokslininkai-mums-siulo-valgyti-vabzdzius-ir-laboratorijose-augina-mesa-%e2%80%a8/"}>
        <article className="card">
          <img src="https://aplinkosaugosegzaminas.lrt.lt/static-nae/cow-5608144_1920-1080x675.jpg" alt=""/>
          <article className="content">
            <h6>Kodėl mokslininkai mums siūlo valgyti vabzdžius ir laboratorijose augina mėsą?
            </h6>
            <p>
            </p>
          </article>
        </article>
      </a>

      <a target="_blank"
         href={"http://zaliojipolitika.lt/puse-es-valstybiu-atsisako-palmiu-aliejaus-ir-jo-produktu-biodegaluose/"}>
        <article className="card">
          <img src="https://aplinkosaugosegzaminas.lrt.lt/static-nae/transportas-1080x675.png" alt=""/>
          <article className="content">
            <h6>Pusė ES valstybių atsisako palmių aliejaus ir jo produktų biodegaluose
            </h6>
            <p>
            </p>
          </article>
        </article>
      </a>

      <a target="_blank"
         href={"http://zaliojipolitika.lt/gydancios-zuvys-baltijos-juros-dugne-ar-vaistai-kuriu-nepageidavome/"}>
        <article className="card">
          <img src="https://aplinkosaugosegzaminas.lrt.lt/static-nae/medications-257336_1920-1080x675.jpg" alt=""/>
          <article className="content">
            <h6>Gydančios žuvys Baltijos jūros dugne ar vaistai, kurių nepageidavome?
            </h6>
            <p>
            </p>
          </article>
        </article>
      </a>

      <a target="_blank"
         href={"http://zaliojipolitika.lt/kodel-zydi-vanduo/"}>
        <article className="card">
          <img src="https://aplinkosaugosegzaminas.lrt.lt/static-nae/LZP-fb-post-63.png" alt=""/>
          <article className="content">
            <h6>Kodėl „žydi“ vanduo?
            </h6>
            <p>
            </p>
          </article>
        </article>
      </a>

      <a target="_blank"
         href={"http://zaliojipolitika.lt/darnustransportas/"}>
        <article className="card">
          <img src="https://aplinkosaugosegzaminas.lrt.lt/static-nae/bus-1263266_1920-1080x675.jpg" alt=""/>
          <article className="content">
            <h6>Kaip keliauti nelaukiant spūstyse ir neteršiant oro?
            </h6>
            <p>
            </p>
          </article>
        </article>
      </a>

      <a target="_blank"
         href={"http://zaliojipolitika.lt/sildymas/"}>
        <article className="card">
          <img src="https://aplinkosaugosegzaminas.lrt.lt/static-nae/roof-768735_1920-2-1080x675.jpg" alt=""/>
          <article className="content">
            <h6>Kaip tinkamai prižiūrėti šildymo sistemą?
            </h6>
            <p>
            </p>
          </article>
        </article>
      </a>

      <a target="_blank"
         href={"http://zaliojipolitika.lt/mokslininkai-istyre-lietuvos-upiu-vandeni-visuose-meginiuose-rado-mikroplastiko/"}>
        <article className="card">
          <img src="https://aplinkosaugosegzaminas.lrt.lt/static-nae/FTMC-Aplinkotyros-sk.-laboratorija_FTMC-nuotrauka-1080x675.jpg" alt=""/>
          <article className="content">
            <h6>Mokslininkai ištyrė Lietuvos upių vandenį: visuose mėginiuose rado mikroplastiko
            </h6>
            <p>
            </p>
          </article>
        </article>
      </a>

      <a target="_blank"
         href={"http://zaliojipolitika.lt/kada-neliks-spusciu-ir-tarsius-automobilius-pakeis-ekologiskas-transportas/"}>
        <article className="card">
          <img src="https://aplinkosaugosegzaminas.lrt.lt/static-nae/original-1-1080x675.jpeg" alt=""/>
          <article className="content">
            <h6>Kada neliks spūsčių ir taršius automobilius pakeis ekologiškas transportas?
            </h6>
            <p>
            </p>
          </article>
        </article>
      </a>

      <a target="_blank"
         href={"http://zaliojipolitika.lt/tvarus-aplinkai-draugiski-ir-saugus-produktai-kaip-juos-atskirti/"}>
        <article className="card">
          <img src="https://aplinkosaugosegzaminas.lrt.lt/static-nae/LZP-fb-post-30-copy.png" alt=""/>
          <article className="content">
            <h6>Tvarūs, aplinkai draugiški ir saugūs produktai: kaip juos atskirti?
            </h6>
            <p>
            </p>
          </article>
        </article>
      </a>

      <a target="_blank"
         href={"http://zaliojipolitika.lt/remigijus-lapinskas-10-zingsniu-link-darnaus-miesto/"}>
        <article className="card">
          <img src="https://aplinkosaugosegzaminas.lrt.lt/static-nae/Remigijus-Lapinskas-1080x675.jpg" alt=""/>
          <article className="content">
            <h6>Remigijus Lapinskas: 10 žingsnių link darnaus miesto
            </h6>
            <p>
            </p>
          </article>
        </article>
      </a>

      <a target="_blank"
         href={"http://zaliojipolitika.lt/zalias-miestas-misija-imanoma/"}>
        <article className="card">
          <img src="https://aplinkosaugosegzaminas.lrt.lt/static-nae/virtuali-konferencija-3-1-1080x497.png" alt=""/>
          <article className="content">
            <h6>Žalias miestas – misija (ne)įmanoma?
            </h6>
            <p>
            </p>
          </article>
        </article>
      </a>

      <a target="_blank"
         href={"http://zaliojipolitika.lt/tvarus-teritoriju-planavimas-ar-vilnius-yra-zalias-miestas/"}>
        <article className="card">
          <img src="https://aplinkosaugosegzaminas.lrt.lt/static-nae/lithuania-966145_640.jpg" alt=""/>
          <article className="content">
            <h6>Tvarus teritorijų planavimas: ar Vilnius yra žalias miestas?
            </h6>
            <p>
            </p>
          </article>
        </article>
      </a>

      <a target="_blank"
         href={"http://zaliojipolitika.lt/vilniaus-aplinkos-bukle-kaip-ja-vertina-ekspertai/"}>
        <article className="card">
          <img src="https://aplinkosaugosegzaminas.lrt.lt/static-nae/bridge-5683287_1920-1080x675.jpg" alt=""/>
          <article className="content">
            <h6>Vilniaus aplinkos būklė: kaip ją vertina ekspertai?
            </h6>
            <p>
            </p>
          </article>
        </article>
      </a>

      <a target="_blank"
         href={"http://zaliojipolitika.lt/misku-plotai-pasaulyje-mazeja-nerima-kelianciu-tempu/"}>
        <article className="card">
          <img src="https://aplinkosaugosegzaminas.lrt.lt/static-nae/B6.-XXII-amzius-4-1080x675.jpg" alt=""/>
          <article className="content">
            <h6>Miškų plotai pasaulyje mažėja nerimą keliančiu tempu
            </h6>
            <p>
            </p>
          </article>
        </article>
      </a>

      <a target="_blank"
         href={"http://zaliojipolitika.lt/trisdesimties-minuciu-puodelis-dvidesimties-minuciu-maiselis-ir-keturi-simtai-metu-juros-dugne/"}>
        <article className="card">
          <img src="https://aplinkosaugosegzaminas.lrt.lt/static-nae/coffeetogo-3926395_1920-1080x675.jpg" alt=""/>
          <article className="content">
            <h6>Trisdešimties minučių puodelis, dvidešimties minučių maišelis ir keturi šimtai metų jūros dugne
            </h6>
            <p>
            </p>
          </article>
        </article>
      </a>

      <a target="_blank"
         href={"http://zaliojipolitika.lt/vartotoju-dilema-ekologija-ar-zalioji-migla/"}>
        <article className="card">
          <img src="https://aplinkosaugosegzaminas.lrt.lt/static-nae/clothing-store-984396_1920-1080x675.jpg" alt=""/>
          <article className="content">
            <h6>Vartotojų dilema: ekologija ar žalioji migla?
            </h6>
            <p>
            </p>
          </article>
        </article>
      </a>

      <a target="_blank"
         href={"http://zaliojipolitika.lt/greitoji-mada-vartojame-per-daug-ir-per-greitai/"}>
        <article className="card">
          <img src="https://aplinkosaugosegzaminas.lrt.lt/static-nae/Drabuziai-1080x675.jpg" alt=""/>
          <article className="content">
            <h6>Greitoji mada: vartojame per daug ir per greitai
            </h6>
            <p>
            </p>
          </article>
        </article>
      </a>

      <a target="_blank"
         href={"http://zaliojipolitika.lt/ieva-budraite-100-sekundziu-iki-pasaulio-pabaigos%e2%80%a8/"}>
        <article className="card">
          <img src="https://aplinkosaugosegzaminas.lrt.lt/static-nae/Ieva-Budraite-1080x675.jpg" alt=""/>
          <article className="content">
            <h6>Ieva Budraitė: 100 sekundžių iki pasaulio pabaigos
            </h6>
            <p>
            </p>
          </article>
        </article>
      </a>

      <a target="_blank"
         href={"http://zaliojipolitika.lt/kur-baigiasi-mada-ir-prasideda-tarsa/"}>
        <article className="card">
          <img src="https://aplinkosaugosegzaminas.lrt.lt/static-nae/Drabuziai-kvadratas-1080x675.jpg" alt=""/>
          <article className="content">
            <h6>Kur baigiasi mada ir prasideda tarša?
            </h6>
            <p>
            </p>
          </article>
        </article>
      </a>

      <a target="_blank"
         href={"http://zaliojipolitika.lt/ziedine-ekonomika-taisyti-ar-isigyti-nauja/"}>
        <article className="card">
          <img src="https://aplinkosaugosegzaminas.lrt.lt/static-nae/tool-1957451_1920-1080x675.jpg" alt=""/>
          <article className="content">
            <h6>Žiedinė ekonomika: taisyti ar įsigyti naują?
            </h6>
            <p>
            </p>
          </article>
        </article>
      </a>



    </section>

  )
}
