import React from "react"
import { Link } from "gatsby"
import Layout from "../Components/Layout"
import Box from "../Components/Box"
import TopIlliustration from "../assets/images/about-illiustration.jpg"
import Forest from "../assets/svg/forest.svg"
import { RaisedButton } from "../Components/Buttons"
import Ecosystem from "../assets/svg/ecosystem.svg"

import  Education  from "../Components/Education"


export default () => (
  <Layout>



    <div className="block-header-left " style={{marginBottom:"64px"}}>
      <h2 style={{paddingBottom:"8px", marginBottom:"0px"}}>Pasiruošk</h2>
      <p>Čia rasi naudingos informacijos, kuri padės gilinti žinias apie mus supančią aplinką ir geriau pasiruošti egzaminui. </p>
    </div>




 <Education></Education>





    <div className="grid bg-black" style={{padding:0}}>
      <div className="grid--cell w-50 ">


        <div>
          <div className="index--about-project">
            <div className="text-container" style={{color:"white"}}>
              {/*<p>Iki egzamino liko 0 dienų</p>*/}

              <h2 style={{color:"white"}}>Registruokis ir pradėk
                ruoštis egzaminui
                jau dabar!</h2>
              {/*<RaisedButton href="https://aplinkosaugosegzaminas.lrt.lt/registration" >Registruotis</RaisedButton>*/}
            </div>
          </div>
        </div>




      </div>

      <div className="grid--cell w-50 " style={{display:"flex", flexDirection:"column-reverse"}}>
        <img src={Ecosystem} alt="" style={{width : "100%", marginBottom: 0}}/>
      </div>
    </div>


  </Layout>
)
